import React, { useContext, useEffect, useState } from "react";
import { FaUserAlt, FaBlackTie, FaUsers } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { DataContext } from "../../context/Datastate";
import { Link, useNavigate } from "react-router-dom";
import { Bar, Line, Pie, Doughnut, Radar } from 'react-chartjs-2';
import numberData from './barGraphData';
import dailyCandidates from './lineGraphData';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler
} from 'chart.js';
import fetchUtil from "../../utils/fetch.util";
import { JOB_GRAPHS } from "../../constants/apiEndPoints";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler
);

const CARDS = [
  { label: 'Candidates', subHeading: 'added today', color: 'bg-[#FDE2DB]', icon: <FaUsers />, value: 'dailyAddedCandidates', link: '/all_candidates/list', linkState: 'today' },
  { label: 'Candidates', subHeading: 'with video intro', color: 'bg-[#FDE2DB]', icon: <FaUsers />, value: 'allVideoCandidate', link: '/all_candidates/list', linkState: 'withVideo' },
  { label: 'Profiles submitted', subHeading: 'to client', color: 'bg-green-100', icon: <FaBlackTie />, value: 'todayProfileSubmittedToClient', link: '/all_candidates/list', linkState: 'submitToClient' },
  { label: 'All candidates', color: 'bg-[#FDE2DB]', icon: <FaUsers />, value: 'totalCandidate', link: '/all_candidates/list' },
  { label: 'Hot&Immediate', subHeading: 'candidates', color: 'bg-[#FDE2DB]', icon: <FaUsers />, value: 'hotAndImmediate', link: '/all_candidates/list', linkState: 'hotAndImmediate' },
  { label: 'Vendors', color: 'bg-[#DBE6FE]', icon: <FaUserAlt />, value: 'totalVendor', link: '/vendors/list' },
  { label: 'Hot Vendors', color: 'bg-[#DBE6FE]', icon: <FaUserAlt />, value: 'hotVendors', link: '/vendors/list' },
  { label: 'Jobs', color: 'bg-[#FDE2DB]', icon: <FaBlackTie />, value: 'totalJob', link: '/jobs/list' },
  { label: 'Enabled jobs', color: 'bg-green-100', icon: <FaBlackTie />, value: 'totalEnabledJob', link: '/jobs/list', linkState: 'activeJobs' },
];

const jobTypesDistribution = [
  { "type": "Full Time", "count": 200 },
  { "type": "Part Time", "count": 100 },
  { "type": "Internship", "count": 50 }
];

const skillsDistribution = [
  { "name": "React", "count": 150 },
  { "name": "Node.js", "count": 120 },
  { "name": "Python", "count": 80 },
  { "name": "Data Science", "count": 60 },
  { "name": "SQL", "count": 100 }
];
const applicantsByAvailability = [
  { "date": "2024-06-01", "immediatelyAvailable": 10, "notImmediatelyAvailable": 5 },
  { "date": "2024-06-02", "immediatelyAvailable": 12, "notImmediatelyAvailable": 8 },
  { "date": "2024-06-03", "immediatelyAvailable": 18, "notImmediatelyAvailable": 7 },
  { "date": "2024-06-04", "immediatelyAvailable": 5, "notImmediatelyAvailable": 5 }
];
const immediatelyAvailableApplicants = [
  { "date": "2024-06-01", "count": 5 },
  { "date": "2024-06-02", "count": 8 },
  { "date": "2024-06-03", "count": 12 },
  { "date": "2024-06-04", "count": 3 },
  { "date": "2024-06-05", "count": 4 },
  { "date": "2024-06-06", "count": 1 },
  { "date": "2024-06-07", "count": 8 },
  { "date": "2024-06-08", "count": 2 },
  { "date": "2024-06-09", "count": 5 },
  { "date": "2024-06-10", "count": 6 },
  { "date": "2024-06-11", "count": 3 },
  { "date": "2024-06-12", "count": 1 },
  { "date": "2024-06-13", "count": 10 },
  { "date": "2024-06-14", "count": 7 },
  { "date": "2024-06-15", "count": 4 },
  { "date": "2024-06-16", "count": 2 },
  { "date": "2024-06-17", "count": 1 },
];

const Dashboard = () => {
  const { allCount, } = useContext(DataContext);
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState();

  const getSixMonthsEarlierDate = () => {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 3);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [state, setState] = useState({
    startDate: getSixMonthsEarlierDate(),
    endDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1) > 9 ? "" : +"0"}${new Date().getMonth() + 1}-${(new Date().getDate() > 9 ? "" : "0")}${new Date().getDate()}`,
  });

  useEffect(() => {
    fetchUtil.POST(JOB_GRAPHS, state)
      .then((response) => {
        setGraphData(response.Result);
      })
      .catch(err => console.error(err));
  }, [])



  const dailyCandidatesData = {
    labels: graphData?.dateWiseCandidates.map(d => d.date),
    datasets: [
      {
        label: 'Daily Applicants',
        data: graphData?.dateWiseCandidates.map(d => d.count),
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Immediately Available Applicants',
        data: immediatelyAvailableApplicants.map(d => d.count),
        fill: false,
        borderColor: 'rgba(255,99,132,1)',
        tension: 0.1,
      },
    ],
  };

  const candidatesPerJobData = {
    labels: graphData?.findActiveJob.map(job => job.title),
    datasets: [
      {
        label: 'Applicants per Job',
        data: graphData?.findActiveJob.map(job => job.applicants),
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  const applicantsDistributionData = {
    labels: ['Sent to Clients', 'Total Candidates'],
    datasets: [
      {
        data: [allCount.todayProfileSubmittedToClient, allCount.totalCandidate],
        backgroundColor: ['#FF8531', '#FDE2DB'],
      },
    ],
  };

  const jobTypesDistributionData = {
    labels: jobTypesDistribution.map(job => job.type),
    datasets: [
      {
        label: 'Applicants by Job Type',
        data: jobTypesDistribution.map(job => job.count),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const skillsDistributionData = {
    labels: skillsDistribution.map(skill => skill.name),
    datasets: [
      {
        label: 'Skills Distribution',
        data: skillsDistribution.map(skill => skill.count),
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
      },
    ],
  };

  const stackedBarData = {
    labels: applicantsByAvailability.map(item => item.date),
    datasets: [
      {
        label: 'Immediately Available',
        data: applicantsByAvailability.map(item => item.immediatelyAvailable),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
      {
        label: 'Not Immediately Available',
        data: applicantsByAvailability.map(item => item.notImmediatelyAvailable),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  const stackedBarOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Applicants Availability',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        position: 'top',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const handleSubmit = () => {
    fetchUtil.POST(JOB_GRAPHS, state)
      .then((response) => {
        setGraphData(response.Result);
      })
      .catch(err => console.error(err));
  }

  return (
    <>
      <div className="w-full flex h-screen overflow-x-scroll">
        <div className="w-[100%] px-4 pt-5">
          <div className="bg-theme-white w-full">
            <div className="mx-3">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-theme-black mt-0 text-xl font-semibold">
                  Dashboard
                </h1>
                <span className="font-semibold text-theme-black">Welcome Admin</span>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                {CARDS.map((card, index) => (
                  <div key={index} className={`${card.color} p-3 rounded-2xl h-[120px] cursor-pointer`}
                    onClick={() => navigate(card.link, { state: card?.linkState })}>
                    <div className="flex justify-between items-start mb-2">
                      <div className="flex flex-col justify-between h-[6rem]">
                        <div className="text-sm md:text-base font-semibold">{card.label}<br /><span className=" font-medium text-[11px]">{card?.subHeading}</span></div>
                        <div className="font-semibold text-lg md:text-xl lg:text-2xl xl:text-3xl">{allCount[card.value]}</div>
                      </div>
                      <div>{card.icon}</div>
                    </div>
                  </div>))}
              </div>
              <div className="my-10">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex gap-3 items-center mb-4">
                    <span className="capitalize text-sm font-semibold">
                      From
                    </span>
                    <input
                      type="date"
                      value={state?.startDate}
                      className="text-[#31374A] w-[25%] font-semibold  border py-1 px-2  border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                      name="startDate"
                      id="startDate"
                      onChange={(e) => {
                        setState({ ...state, startDate: e.target.value });
                      }}
                    />
                    <span className="capitalize text-sm font-semibold">
                      To
                    </span>
                    <input
                      type="date"
                      value={state?.endDate}
                      className="text-[#31374A] w-[25%]  font-semibold  border py-1 px-2  border-gray-300 text-sm placeholder:text-slate-400 h-9  focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1"
                      name="endDate"
                      id="endDate"
                      onChange={(e) => {
                        setState({ ...state, endDate: e.target.value });
                      }}
                    />
                    <button
                      type="button"
                      className="bg-theme-color px-8 text-sm py-2 rounded disabled:opacity-50 hover:bg-orange-500 text-white ml-3"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="m-4">
                    <Line data={dailyCandidatesData} />
                  </div>
                  <div className="m-4">
                    <Bar data={candidatesPerJobData} />
                  </div>
                  <div className="m-4 w-2/3">
                    <Doughnut data={applicantsDistributionData} />
                  </div>
                  <div className="m-4 w-2/3">
                    <Pie data={jobTypesDistributionData} />
                  </div>
                  <div className="m-4 w-2/3">
                    <Radar data={skillsDistributionData} />
                  </div>
                  <div className="m-4">
                    <Bar data={stackedBarData} options={stackedBarOptions} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
