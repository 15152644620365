import React, { createContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const admin = 'admin';
  // const BASE_URL = "https://getdeveloper.in/api";

  let BASE_URL = ""
  let url = window.location.hostname;
  console.log("+++",url)
  if(url.includes("admin")){
     BASE_URL = "https://api.getdeveloper.in/";
  }

 else if (url.includes("localhost")){
  BASE_URL="http://localhost:4000"
  console.log("--local=",BASE_URL)
}
else{
  BASE_URL=`http://184.72.222.95:4000`
  console.log("---+++++=",BASE_URL)
}

  // const BASE_URL = "https://api.getdeveloper.in";
  const navigate = useNavigate();
  const location = useLocation();
  const [resultLoginState, setResultLoginState] = useState("");
  const [checkLogin, setCheckLogin] = useState(false);
  const [logout, setLogout] = useState(false);
  //   const [publish, setPublish] = useState(false);
  //   const [passModal, setIsPassModal] = useState(false);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [globalLoader, setGlobalLoader] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [job, setJob] = useState([]);
  const [client, setClient] = useState([]);
  const [technology, setTechnology] = useState([]);
  const [technologyId, setTechnologyid] = useState({});
  const [allTechnology, setAllTechnology] = useState([]);
  const [singleTechnology, setSingleTechnology] = useState({});
  const [singleStatus, setSingleStatus] = useState({});
  const [status, setStatus] = useState([]);
  const [city, setCity] = useState([]);
  const [cityId, setCityid] = useState({});
  const [singleCity, setSingleCity] = useState({});
  const [addcityModal, setAddCityModal] = useState(false);
  const [updatecityModal, setUpdateCityModal] = useState(false);
  const [editCity, setEditCity] = useState([]);
  const [id, setId] = useState("");
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [deleteBtnId, setDeleteBtnId] = useState("");
  const [allCount, setAllCount] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [candidate, setCandidate] = useState([]);
  const [candidatebyvendor, setCandidatebyvendor] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [addtechModal, setAddTechModal] = useState(false);
  const [updatetechModal, setUpdateTechModal] = useState(false);
  const [editTechnology, setEditTechnology] = useState([]);
  const [addstatusModal, setAddStatusModal] = useState(false);
  const [updatestatusModal, setUpdateStatusModal] = useState(false);
  const [editStatus, setEditStatus] = useState([]);
  const [candidateView, setCandidateView] = useState({});
  const [isLogin, setisLogin] = useState(false);
  const [loader, setLoader] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [recruiter, setRecruiter] = useState([]);
  const [inHouseJob, setInHouseJob] = useState([]);
  const [subAdmin, setSubAdmin] = useState([]);
  const [viewcandidate, setViewCandidate] = useState([]);
  const [openCandidate, setOpenCandidate] = useState([]);

  let adminToken = JSON.parse(localStorage.getItem("adminToken"));
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let admin_id = userInfo?.result;

  //all candidate filter date
  function getFormattedDate() {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const todayFormatted = getFormattedDate();
//////////////////////sendAllJobToVendor/////////////

const sendAllJobToVendor=()=>{
  const myHeaders = new Headers();
  myHeaders.append("token", adminToken);    
  const raw = "";
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  
  fetch(BASE_URL+"/admin/sendAllJobToVendor", requestOptions)
    .then((response) => response.json())
    .then((result) => {console.log(result)
    if(result.statusCode==200){
      setButtonLoader(false);
      toast.success(result.message);
    }
    else{
      toast.error(result.message)
    }
    })
    .catch((error) => console.error(error));
}



  ///////sendAllCandidateToVendor  ////////////////////


  const sendAllCandidateToVendor=()=>{
    const myHeaders = new Headers();
    myHeaders.append("token", adminToken);    
    const raw = "";
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch(BASE_URL+"/admin/sendAllCandidateToVendor", requestOptions)
      .then((response) => response.json())
      .then((result) => {console.log(result)
      if(result.statusCode==200){
        setButtonLoader(false);
        toast.success(result.message);
      }
      else{
        toast.error(result.message)
      }
      })
      .catch((error) => console.error(error));
  }

  //////////////////////

////////////////////////////send email to all /////////////////////////////////////////

const sendEmailtoAll=()=>{
  const myHeaders = new Headers();
  myHeaders.append("token", adminToken);    
  const raw = "";
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  
  fetch(BASE_URL+"/admin/sendEmailToAll", requestOptions)
    .then((response) => response.json())
    .then((result) => {console.log(result)
    if(result.statusCode==200){
      setButtonLoader(false);
      toast.success(result.message);
    }
    else{
      toast.error(result.message)
    }
    })
    .catch((error) => console.error(error));
}

 
  //----------------------------------------- Login API --------------------------------

  const LoginApi = (state) => {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: state.email,
      password: state.password,
    });
    console.log("gg=================",raw)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL+"/admin/login", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("=========================result",result)
        if (result.statusCode === 200) {
          localStorage.setItem("userInfo", JSON.stringify(result));
          localStorage.setItem("adminToken", JSON.stringify(result.token));
          setButtonLoader(false);
          setisLogin(true);
          toast.success(result.message);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        } else if (
          result.message === "TOKEN_EXPIRED" ||
          result.message === "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          // navigate("/");
          setTimeout(() => {
            navigate("/");
          }, 500);
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          setResultLoginState(result.message);
          setButtonLoader(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Forgot password API---------------------------------

  const forgotPasswordApi = (state) => {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: state.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `${admin}/forgotPassword`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setButtonLoader(false);
        }
        // else if (
        //   result.message == "TOKEN_EXPIRED" ||
        //   result.message == "INVALID_TOKEN"
        // ) {
        //   toast.error("Token Expired,Login Again!");
        //   navigate("/");
        //   localStorage.removeItem("userInfo");
        //   localStorage.removeItem("adminToken");
        // }
      })
      .catch((error) => console.log("error", error));
  };

  // ------------------------------Reset Password API---------------------------------

  const resetPasswordApi = (state, userId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password: state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `${admin}/resetpassword/${userId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setButtonLoader(false);
        }
        // else if (
        //   result.message == "TOKEN_EXPIRED" ||
        //   result.message == "INVALID_TOKEN"
        // ) {
        //   toast.error("Token Expired,Login Again!");
        //   navigate("/");
        //   localStorage.removeItem("userInfo");
        //   localStorage.removeItem("adminToken");
        // }
      })
      .catch((error) => console.log("error", error));
  };

  // Create Password
  const CreatePasswordApi = (state) => {
    var myHeaders = new Headers();
    myHeaders.append("token", JSON.parse(localStorage.getItem("adminToken")));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password: state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + "user/resetpassword", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const filteredData = (responseData) => {
    return responseData.filter((item) => item.isActive === true);
  };
  // ------------------------------Add candidate API---------------------------------

  const AddCandidateApi = (state) => {
    setDisableSave(true);
    var myHeaders = new Headers();
    let vendorid = JSON.parse(localStorage?.getItem("id"));
    myHeaders.append("token", adminToken);

    var formdata = new FormData();
    var formdata = new FormData();
    formdata.append("name", state.name);
    formdata.append("linkedinprofile", state.linkedinprofile);
    formdata.append("email", state.email);
    formdata.append("resumelink", state.resumelink);
    formdata.append("primarytechnology", state.primarytechnology);
    formdata.append("totalExp[years]", state.totalExp.years);
    formdata.append("totalExp[months]", state.totalExp.months);
    formdata.append("phonenumber", state.phonenumber);
    formdata.append("rate", state.rate);
    formdata.append("ctc", state.ctc);
    formdata.append("ectc", state.ectc);
    formdata.append("joiningtime", state.joiningtime);
    formdata.append("relevantExp[years]", state.relevantExp.years);
    formdata.append("relevantExp[months]", state.relevantExp.months);
    formdata.append("status", state.status);
    formdata.append("pf", state.pf);
    formdata.append("pannumber", state.pannumber);
    formdata.append("aadhar", state.aadhar);
    // formdata.append("resumelink","https://" + state.resumelink);
    formdata.append("vendor", state.vendor);
    formdata.append("salarySlip", state.salarySlip);

    state.candidateAssigned &&
      formdata.append("candidateAssigned", state.candidateAssigned);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(BASE_URL + `/${admin}/addcandidate/${state.vendor}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/all_candidates/list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
//===========================applyAdminJobs+++++++++++++++
const adminApplyJobs=(state)=>{
  const myHeaders = new Headers();
  myHeaders.append("token", adminToken);
  console.log("state+++++++++++=",adminToken)

  console.log("state+++++++++++=",state)
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow"
  };
  
  fetch("http://localhost:4000/job/jobApplyByAdmin?candidateId=66da80374a933513d7c1e295&jobId=66d94d0b4a933513d7c1b92c", requestOptions)
    .then((response) => response.json())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
}
  // ------------------------------Add vendor API---------------------------------

  const AddVendorApi = (state) => {
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      admin_id: admin_id,
      vendorname: state.vendorname,
      website: state.website,
      address: state.address,
      contactpersonname: state.contactpersonname,
      contactpersonphone: state.contactpersonphone,
      alternatepersonname: state.alternatepersonname,
      alternatepersonphone: state.alternatepersonphone,
      technology: state.technology,
      location: state.location,
      email: state.email,
      password: state.password,
      isSendEmail: state.isSendEmail,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/vendor/addVendor`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/vendors/list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setDisableSave(true);
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
          setDisableSave(false);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  // ------------------vendor mail api ---------------------------
  const vendoMailApi = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/vendor/vendoremail/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  // -----------------------get vendor api----------------------

  const getVendorById = () => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/vendor/getVendor/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setVendor(result.result);
          // setVendorName()
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //---------------------------------------update vendor api-------------------------------
  const updateVendorApi = (state, id) => {
    console.log("state",state,state.hotVendor)
    setId(id);
    setDisableSave(true);
    console.log('testing',state.hotVendor)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);
    
    var raw = JSON.stringify({
      vendorname: state.vendorname,
      website: state.website,
      address: state.address,
      technology: state.technology,
      location: state.location,
      contactpersonname: state.contactpersonname,
      contactpersonphone: state.contactpersonphone,
      alternatepersonname: state.alternatepersonname,
      alternatepersonphone: state.alternatepersonphone,
      email: state.email,
      password: state.password,
      hotVendor:state.hotVendor
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/vendor/updateVendor/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          console.log("donnnnnn")
          setTimeout(() => {
            setDisableSave(false);
            navigate("/vendors/list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //----------------------------delete vendor api---------------------------
  const deleteVendorApi = (id) => {
    const limit = 100;
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/vendor/deletevendor/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          vendorPaginationApi(1, limit);
          // getVendorById();
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // delete candidate api
  const deleteCandidateApi = (id) => {
    const limit = 100;
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/${admin}/deletebyadmin/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          candidatePaginationApi(1, limit);
          // getVendorById();
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------Search vendor api------------
  const searchVendorApi = (key) => {
    // let adminToken = JSON.parse(localStorage.getItem("adminToken"));
    var myHeaders = new Headers();

    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/vendor/allvendor/?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setVendor(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-----------------vendor Pagination Api-----------
  const vendorPaginationApi = (
    page,
    limit,
    location = "",
    technology = "",
    status = "true"
  ) => {
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url = `${BASE_URL}/vendor/allvendor?page=${page}&limit=${limit}`;
    if (location !== "") {
      url += `&location=${location}`;
    }
    if (technology !== "") {
      url += `&technology=${technology}`;
    }
    if (status !== "") {
      url += `&isActive=${status}`;
    }
    // fetch(
    //   BASE_URL + `/vendor/allvendor/?page=${page}&limit=${limit}`,

    //   requestOptions
    // )
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setVendor(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // vendor non pagination api

  const getAllVendorsApi = (page) => {
    setLoader(true);
    const limit = 100;
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/vendor/allvendor`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setVendor(result.result);
          setGlobalLoader(false);
          // setPageCount(result.pageCount);
          // setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //---------------------add client api-----------------
  const AddClientApi = (state) => {
    setDisableSave(true);
    // let adminToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);
    var raw = JSON.stringify({
      admin_id: admin_id,
      name: state.name,
      website: state.website,
      contactpersonname: state.contactpersonname,
      phonenumber: state.phonenumber,
      email: state.email,
      address: state.address,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/client/addclient`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/client_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  // ----------------------------------------get client api----------------------

  const getClientApi = () => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/client/getclient`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setClient(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //---------------------------------------update client api-------------------------------
  const updateClientApi = (state, id) => {
    console.log(state, "statecomment");
    setId(id);
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);

    var raw = JSON.stringify({
      name: state.name,
      website: state.website,
      contactpersonname: state.contactpersonname,
      phonenumber: state.phonenumber,
      email: state.email,
      address: state.address,
      comment: state.comment,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/client/updateclient/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/client_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //----------------------------delete client api---------------------------
  const deleteClientApi = (id) => {
    const limit = 100;
    var myHeaders = new Headers();
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/client/deleteclient/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          // getJobApi();
          clientPaginationApi(1, limit);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------Search client api------------
  const searchClientApi = (key) => {
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/client/getallclient/?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setClient(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-----------------client Pagination Api-----------
  const clientPaginationApi = (page, lim) => {
    setLoader(true);
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/client/getallclient?page=${page}&limit=${lim}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setClient(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //---------get All client api-------
  const allClientApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/client/getallclient`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setClient(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //-------------get all vendor api--------------
  const allVendorApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/vendor/allvendor`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setVendor(filteredData(result.result));
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //------- enable client api--------------

  const ClientEnableApi = (id) => {
    const limit = 50;
    // setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var raw = "";

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/client/enableclient/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          clientPaginationApi(1, limit);

          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //---------------------add job api-----------------
  const AddJobApi = (state, worknature, priority) => {
    setDisableSave(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);
    var raw = JSON.stringify({
      admin_id: admin_id,
      jobtitle: state.jobtitle,
      totalExp: state.totalExp,
      positions: state.positions,
      worknature: worknature,
      location: state.location,
      priority: priority,
      jobdescription: state.jobdescription,
      technology: state.technology,
      positiontype: state.positiontype,
      rate: state.rate,
      client: state.client,
      assignedTo: state.assignedTo,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/job/addJob`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/jobs/list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  //-------------------- add technology api-------------
  const addTechnologyApi = (state) => {
    const limit = 50;
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      admin_id: admin_id,
      technologyname: state.technologyname,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/technology/addtechnology`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            setAddTechModal(false);
            technologyPaginationApi(1, limit);
            navigate("/technology_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //------------------------get technology api------------
  const getTechnologyApi = (id) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/technology/gettechnology/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSingleTechnology(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //-------------update technology api--------------
  const updateTechnologyApi = (technologyname, id) => {
    const limit = 50;
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);

    var raw = JSON.stringify({
      technologyname: technologyname,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/technology/updatetechnology/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            setUpdateTechModal(false);
            technologyPaginationApi(1, limit);
            navigate("/technology_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //-----------------technology Pagination Api-----------
  const technologyPaginationApi = (page) => {
    setLoader(true);
    const limit = 50;
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/technology/alltechnology/?page=${page}&limit=${limit}`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setTechnology(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // technology non pagination api
  const getAllTechnologyApi = () => {
    setLoader(true);
    const limit = 50;
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/technology/alltechnology`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode == 200) {
          setTechnology(result.result);
          setGlobalLoader(false);
          // setPageCount(result.pageCount);getAllVendorsApi
          // setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //technology without token pagination api
  const getAllTechnologyWithoutTokenApi = () => {
    setLoader(true);
    const limit = 300;
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    //  myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/technology/alltechnologies`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setAllTechnology(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        }
        //  } else if (
        //    result.message == "TOKEN_EXPIRED" ||
        //    result.message == "INVALID_TOKEN"
        //  ) {
        //    toast.error("Token Expired,Login Again!");
        //    setCheckLogin(false);
        //    navigate("/");
        //    localStorage.removeItem("userInfo");
        //    localStorage.removeItem("adminToken");
        //  }
      })
      .catch((error) => console.log("error", error));
  };
  //---------get All technology api-------
  const allTechnologyApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/technology/alltechnology`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setTechnology(filteredData(result.result));
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //----------delete technology api--------
  const deleteTechnologyApi = (id) => {
    const limit = 50;
    var myHeaders = new Headers();
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/technology/deletetechnology/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          technologyPaginationApi(1, limit);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //-----------search technology api--------------
  const searchTechnologyApi = (key) => {
    // let adminToken = JSON.parse(localStorage.getItem("adminToken"));
    var myHeaders = new Headers();

    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/technology/alltechnology/?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setTechnology(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-----------------enable disable technology api----------

  const updateTechnologyEnableApi = (id) => {
    const limit = 50;
    // setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var raw = "";

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/technology/enabledisable/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          technologyPaginationApi(1, limit);

          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  // ----------------------------------------get job api----------------------

  const getJobApi = () => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/jobs/getJob`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setJob(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //---------------------------------------update job api-------------------------------
  const updateJobApi = (state, id) => {
    setId(id);
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);

    var raw = JSON.stringify({
      jobtitle: state.jobtitle,
      totalExp: state.totalExp,
      positions: state.positions,
      worknature: state.worknature,
      location: state.location,
      priority: state.priority,
      jobdescription: state.jobdescription,
      technology: state.technology,
      positiontype: state.positiontype,
      rate: state.rate,
      client: state.client,
      action: state.action,
      assignedTo: state.assignedTo,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/job/updateJob/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/jobs/list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //----------------------------delete job api---------------------------
  const deleteJobApi = (id) => {
    const limit = 100;
    var myHeaders = new Headers();
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/job/deleteJob/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          // getJobApi();
          jobPaginationApi(1, limit);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------Search job api------------
  const searchJobApi = (key) => {
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();

    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/job/alljob/?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setJob(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-----------------job Pagination Api-----------
  const jobPaginationApi = (
    page,
    limit,
    location = "",
    priority = "",
    technology = "",
    client = "",
    worknature = "",
    status = "true"
  ) => {
    // const limit = 100;
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let url = `${BASE_URL}/job/alljob?page=${page}&limit=${limit}`;
    if (location !== "") {
      url += `&location=${location}`;
    }
    if (priority !== "") {
      url += `&priority=${priority}`;
    }
    if (technology !== "") {
      url += `&technology=${technology}`;
    }
    if (client !== "") {
      url += `&client=${client}`;
    }
    if (worknature !== "") {
      url += `&worknature=${worknature}`;
    }
    if (status !== "") {
      url += `&isActive=${status}`;
    }
    // fetch(BASE_URL + `/job/alljob?page=${page}&limit=${limit}`, requestOptions)
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setJob(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //-------------------- add status api-------------
  const addStatusApi = (state) => {
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      admin_id: admin_id,
      statusname: state.statusname,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/status/addstatus`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setAddStatusModal(false);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/status-list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //------------------------get status api------------
  const getStatusApi = (id) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/status/getstatus/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setStatus(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //-------------update status api--------------
  const updateStatusApi = (state, id) => {
    const limit = 50;
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);

    var raw = JSON.stringify({
      statusname: state,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/status/updatestatus/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/status-list");
            statusPaginationApi(1, limit);
            setUpdateStatusModal(false);
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //-----------------status Pagination Api-----------
  const statusPaginationApi = (page) => {
    setLoader(true);
    const limit = 50;
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/status/getallstatus/?page=${page}&limit=${limit}`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setStatus(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // status non pagination api
  const getAllStatusApi = (page) => {
    setLoader(true);
    const limit = 50;
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/status/getallstatus/`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setStatus(filteredData(result.result));
          setGlobalLoader(false);
          // setPageCount(result.pageCount);
          // setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------getallstatus api----------

  const getallstatusApi = () => {
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/status/getallstatus`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setStatus(filteredData(result.result));
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //----------delete status api--------
  const deleteStatusApi = (id) => {
    const limit = 50;
    var myHeaders = new Headers();
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/status/deletestatus/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          statusPaginationApi(1, limit);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //-----------search status api--------------
  const searchStatusApi = (key) => {
    // let adminToken = JSON.parse(localStorage.getItem("adminToken"));
    var myHeaders = new Headers();

    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/status/getallstatus/?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setStatus(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //------------enable disable status api----------
  const updateStatusEnableApi = (id) => {
    const limit = 50;
    // setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var raw = "";
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/status/enabledisablestatus/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          statusPaginationApi(1, limit);
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //-------------------- add city api-------------
  const addCityApi = (state) => {
    const limit = 50;
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      admin_id: admin_id,
      cityname: state.cityname,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/city/addcity`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            setAddCityModal(false);
            cityPaginationApi(1, limit);
            navigate("/city_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //------------------------get city api------------
  const getCityApi = (id) => {
    setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/city/getcity/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSingleCity(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //-------------update city api--------------
  const updateCityApi = (cityname, id) => {
    const limit = 50;
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);

    var raw = JSON.stringify({
      cityname: cityname,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/city/updatecity/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            setUpdateCityModal(false);
            cityPaginationApi(1, limit);
            navigate("/city_list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //-----------------city Pagination Api-----------
  const cityPaginationApi = (page) => {
    setLoader(true);
    const limit = 50;
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/city/getallcity/?page=${page}&limit=${limit}`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setCity(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //---------all city api-------------
  const allCityApi = () => {
    setLoader(true);
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/city/getallcity`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode === 200) {
          setCity(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //----------delete city api--------
  const deleteCityApi = (id) => {
    const limit = 50;
    var myHeaders = new Headers();
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/city/deletecity/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          // getJobApi();
          cityPaginationApi(1, limit);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //-----------search city api--------------
  const searchCityApi = (key) => {
    // let adminToken = JSON.parse(localStorage.getItem("adminToken"));
    var myHeaders = new Headers();

    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/city/getallcity/?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setCity(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // enable disable city api ------------------------------------

  const updateCityEnableApi = (id) => {
    const limit = 50;
    // setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var raw = "";

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/city/enabledisable/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          cityPaginationApi(1, limit);

          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  // // Vendor, job Count Api
  const AllCountApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/job/total`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setAllCount(result.Result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------------------------import User Api----------------------

  const importJobApi = (state) => {
    setDisableSave(true);
    // let adminToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var formdata = new FormData();
    formdata.append("file", state.file);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BASE_URL + `/job/import`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/jobs/list");
          }, 2000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //-------update job enable api--------------

  const updateJobEnableApi = (id) => {
    const limit = 50;
    // setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var raw = "";

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/job/enable/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          jobPaginationApi(1, limit);

          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  // search candidates
  const candidatePaginationApi = (
    page,
    limit,
    startTime = null,
    endTime = null,
    vendor = "",
    status = "",
    technology = "",
    candidateassigned = "",
    yearofexperience = "",
    operator = ""
  ) => {
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // let url = BASE_URL + "/getcandidates";

    // if (startTime !== null && endTime !== null) {
    //   url += `?page=${page}&limit=${limit}&startTime=${startTime}&endTime=${endTime}&`;
    // } else {
    //   url += `?page=${page}&limit=${limit}`;
    // }
    let url = `${BASE_URL}/${admin}/getcandidates?page=${page}&limit=${limit}`;

    if (startTime && endTime) {
      url += `&startTime=${startTime}&endTime=${endTime}`;
    }

    if (vendor !== "") {
      url += `&vendor_id=${vendor}`;
    }

    if (status !== "") {
      url += `&status=${status}`;
    }

    if (technology !== "") {
      url += `&primarytechnology=${technology}`;
    }

    if (candidateassigned !== "") {
      url += `&candidateAssigned=${candidateassigned}`;
    }
    if (yearofexperience && operator) {
      url += `&yearofexperience=${yearofexperience}&operator=${operator}`;
    }
    // fetch(url, requestOptions)
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setCandidate(result.result);
          setGlobalLoader(false);
          if (result.pageCount) {
            setPageCount(result.pageCount);
          }

          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // enable disable vendor api ------------------------------------

  const updateVendoEnableApi = (id) => {
    const limit = 100;
    // setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/vendor/enablevendor/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          vendorPaginationApi(1, limit);

          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  // get candidate by vendor id
  const vendorCandidateApi = (page, id, limit) => {
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/${admin}/getcandidatebyid/${id}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setCandidatebyvendor(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // search all candidates --------------------------------------
  const searchCandidateApi = (key) => {
    var myHeaders = new Headers();
    // const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/getcandidates?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setCandidate(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // search candidatesbyvendorid ------------------------------------
  const searchCandidateByVendorApi = (key, id) => {
    var myHeaders = new Headers();
    // const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/getcandidatebyid/${id}?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setCandidatebyvendor(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // candidate comment api ----------------------------

  const submitCandidateCommentApi = (state, candidateId) => {
    // setGlobalLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var formdata = new FormData();
    formdata.append("isSendEmail", state.isSendEmail);
    formdata.append("joiningtime", state.joiningtime);
    formdata.append("name", state.name);
    formdata.append("phonenumber", state.phonenumber);
    formdata.append("primarytechnology", state.primarytechnology);
    formdata.append("rate", state.rate);
    formdata.append("ctc", state.ctc);
    formdata.append("ectc", state.ectc);
    // formdata.append("id", candidateId);
    formdata.append("linkedinprofile", state.linkedinprofile);
    // formdata.append("linkedinprofile", state.linkedinprofile?.includes("https://") ? state.linkedinprofile : "https://" + state.linkedinprofile);
    // formdata.append("resumelink", state.resumelink?.includes("https://") ? state.resumelink : "https://" + state.resumelink);
    formdata.append("resumelink", state.resumelink);
    formdata.append("totalExp[years]", state.totalExp.years || 0);
    formdata.append("totalExp[months]", state.totalExp.months || 0);
    formdata.append("relevantExp[years]", state.relevantExp.years || 0);
    formdata.append("relevantExp[months]", state.relevantExp.months || 0);
    formdata.append("status", state.status);
    formdata.append("comment", state.comment);
    formdata.append("salarySlip", state.salarySlip);
    state.candidateAssigned &&
      formdata.append("candidateAssigned", state.candidateAssigned);
    // formdata.append("fileFormat", state.fileFormat);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/${admin}/comment/${candidateId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode == 200) {
          toast.success(result.message);
          setCandidateView(result.Result);
          setDisableSave(false);
          setTimeout(() => {
            navigate("/all_candidates/list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };
  //getCandidateDetailsApi------------

  const getCandidateDetailsApi = (candidateid, vendorid) => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}/${admin}/getbyid/${vendorid}/${candidateid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode == 200) {
          setCandidateView(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoader(false));
  };

  //----------------------------delete comment api---------------------------
  const deleteCommentApi = (candidateId, commentId, vendorId) => {
    const limit = 100;
    var myHeaders = new Headers();
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    // {{url}}/commentdelete/6595547f6c78f4a759e9d3fa/653b48a21441927e45302872

    fetch(
      BASE_URL + `/${admin}/commentdelete/${candidateId}/${commentId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          getCandidateDetailsApi(candidateId, vendorId);
          submitCandidateCommentApi();
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // applied job pagination api
  const appliedJobsPaginationApi = (page, limit) => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}/${admin}/getallapplyjobs?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode == 200) {
          setAppliedJobs(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //getAllAdminList---------
  const AllSubadminApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/${admin}/getadmin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setSubAdmin(result.result);
          setGlobalLoader(false);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };
  //subadminPaginationApi----------
  const subAdminPaginationApi = () => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/${admin}/getadmin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode == 200) {
          setSubAdmin(result.result);
          setGlobalLoader(false);
          // setPageCount(result.pageCount);
          // setTotalCount(result.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  //---------------Add SubAdmin api-----------
  const AddSubAdminApi = (state) => {
    setDisableSave(true);
    // let adminToken = JSON.parse(localStorage.getItem("token"));

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);
    var raw = JSON.stringify({
      name: state.name,
      phone: state.phone,
      email: state.email,
      password: state.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/${admin}/subadmin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/subadmin-list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //--------UPDATE SubAdmin Api-----------
  const updateSubAdminApi = (state, id) => {
    setId(id);
    setDisableSave(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", adminToken);

    var raw = JSON.stringify({
      name: state.name,
      phone: state.phone,
      email: state.email,
      password: state.password,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(BASE_URL + `/${admin}/updatesubadmin/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          setTimeout(() => {
            setDisableSave(false);
            navigate("/subadmin-list");
          }, 1000);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setDisableSave(false);
      });
  };

  //----------------------------delete subadmin api---------------------------
  const deleteSubAdminApi = (id) => {
    // const limit = 100;
    var myHeaders = new Headers();
    // let adminToken = JSON.parse(localStorage.getItem("token"));
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BASE_URL + `/${admin}/deletesubadmin/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          toast.success(result.message);
          // getJobApi();
          subAdminPaginationApi();
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");

          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const viewCandidatePaginationApi = (page, id, limit) => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/${admin}/allapplycandidates/${id}?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);

        if (result.statusCode === 200) {
          setViewCandidate(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //------------all candidate api without token
  const getAllOpenCandidateApi = (page, limit) => {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BASE_URL + `/${admin}/getallcandidates?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setOpenCandidate(result.result);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //recruiterPaginationApi
  const recruiterPaginationApi = (page, limit) => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}/${admin}/getassigned?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode == 200) {
          setRecruiter(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------------inhouse job api -----------
  const inHouseJobPaginationApi = (page, limit) => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL}/${admin}/inhouse?page=${page}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.statusCode == 200) {
          setInHouseJob(result.result);
          setGlobalLoader(false);
          setPageCount(result.pageCount);
          setTotalCount(result?.totalCount);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("adminToken");
        } else {
          toast.warning(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //----------search InHouseJob API--------
  const searchInHouseApi = (key) => {
    var myHeaders = new Headers();

    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/${admin}/inhouse?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setInHouseJob(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // search all candidates --------------------------------------
  const searchRecruiterApi = (key) => {
    var myHeaders = new Headers();

    myHeaders.append("token", adminToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(BASE_URL + `/${admin}/getassigned?key=${key}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setRecruiter(result.result);
        } else if (
          result.message == "TOKEN_EXPIRED" ||
          result.message == "INVALID_TOKEN"
        ) {
          toast.error("Token Expired,Login Again!");
          setCheckLogin(false);
          navigate("/");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //  sidbar active
  useEffect(() => {
    if (
      window.location.pathname === "" ||
      window.location.pathname === "/" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/signup/" ||
      window.location.pathname === "/terms-condition" ||
      window.location.pathname === "/terms-condition/" ||
      window.location.pathname === "/privacy-policy" ||
      window.location.pathname === "/privacy-policy/" ||
      window.location.pathname === "/forgot-password" ||
      window.location.pathname === "/developers/candidates"
    ) {
      setCheckLogin(false);
      localStorage.clear();
    } else if (window.location.pathname === "/reset-password/:34567") {
      setCheckLogin(false);
    } else if (
      window.location.pathname === "/vendor" ||
      window.location.pathname === "/vendors/list" ||
      window.location.pathname === "/vendors/add" ||
      window.location.pathname ===
        "/vendors/update/" + window.location.pathname.split("/").pop() ||
      window.location.pathname === "/dashboard" ||
      window.location.pathname === "/jobs/list" ||
      window.location.pathname === "/jobs/add" ||
      window.location.pathname === "/jobs/logs" ||
      window.location.pathname ===
        "/jobs/update/" + window.location.pathname.split("/").pop() ||
      window.location.pathname === "/import_job" ||
      window.location.pathname === "/all_candidates/list" ||
      window.location.pathname ===
        "/cadidatelist-vendor/" +
          window.location.pathname.split("/").pop() ||
      window.location.pathname === "/technology_list" ||
      "/cadidatelist-vendor/" +
        window.location.pathname.split("/").pop() ||
      window.location.pathname === "/status-list" ||
      window.location.pathname === "/view-candidate/" ||
      window.location.pathname === "/subadmin-list" ||
      window.location.pathname === "/add_subadmin" ||
      window.location.pathname ===
        "/update_subadmin/" + window.location.pathname.split("/").pop() ||
      window.location.pathname === "/add_client" ||
      window.location.pathname === "/applied-jobs" ||
      window.location.pathname === "/client_list" ||
      window.location.pathname ===
        "/update_client/" + window.location.pathname.split("/").pop() ||
      window.location.pathname ===
        "/viewcandidate-list/" + window.location.pathname.split("/").pop()
    ) {
      setCheckLogin(true);
    } else {
      setCheckLogin(false);
    }
  }, [window.location.pathname]);

  // All count api load by default
  const pathNames = [
    "",
    "login",
    "forgot-password",
    "create-password",
    "reset-password",
    "developers/candidates",
  ];

  useEffect(() => {
    if (adminToken) {
      if (!pathNames.includes(location.pathname.split("/")[1])) AllCountApi();
    }
  }, [adminToken]);

  return (
    <DataContext.Provider
      value={{
        LoginApi,
        adminToken,
        resultLoginState,
        setResultLoginState,
        ButtonLoader,
        setButtonLoader,
        checkLogin,
        setCheckLogin,
        logout,
        setLogout,
        forgotPasswordApi,
        CreatePasswordApi,
        ButtonLoader,
        setButtonLoader,
        AddVendorApi,
        updateVendorApi,
        deleteVendorApi,
        globalLoader,
        getVendorById,
        searchVendorApi,
        vendor,
        setVendor,
        deleteBtn,
        setDeleteBtn,
        deleteBtnId,
        setDeleteBtnId,
        AllCountApi,
        allCount,
        disableSave,
        pageCount,
        setPageCount,
        totalCount,
        setTotalCount,
        vendorPaginationApi,
        resetPasswordApi,
        // getCountryApi,
        // countryList,
        // setCountryList
        AddJobApi,
        getJobApi,
        updateJobApi,
        deleteJobApi,
        searchJobApi,
        jobPaginationApi,
        importJobApi,
        candidatePaginationApi,
        currentPage,
        setCurrentPage,
        job,
        setJob,
        updateJobEnableApi,
        vendoMailApi,
        candidate,
        setCandidate,
        searchCandidateApi,
        vendorCandidateApi,
        candidatebyvendor,
        searchCandidateByVendorApi,
        updateVendoEnableApi,
        city,
        setCity,
        addCityApi,
        getCityApi,
        updateCityApi,
        cityPaginationApi,
        deleteCityApi,
        searchCityApi,
        addcityModal,
        setAddCityModal,
        updatecityModal,
        setUpdateCityModal,
        editCity,
        setEditCity,
        allCityApi,
        updateCityEnableApi,
        cityId,
        setCityid,
        singleCity,
        setSingleCity,
        technology,
        setTechnology,
        addTechnologyApi,
        getTechnologyApi,
        updateTechnologyApi,
        technologyPaginationApi,
        deleteTechnologyApi,
        allTechnologyApi,
        searchTechnologyApi,
        addtechModal,
        setAddTechModal,
        statusModal,
        setStatusModal,
        addStatusApi,
        status,
        setStatus,
        getStatusApi,
        updateStatusApi,
        statusPaginationApi,
        deleteStatusApi,
        searchStatusApi,
        updatetechModal,
        setUpdateTechModal,
        editTechnology,
        setEditTechnology,
        updateTechnologyEnableApi,
        addstatusModal,
        setAddStatusModal,
        updatestatusModal,
        setUpdateStatusModal,
        editStatus,
        setEditStatus,
        updateStatusEnableApi,
        technologyId,
        setTechnologyid,
        singleTechnology,
        setSingleTechnology,
        singleStatus,
        setSingleStatus,
        setSingleStatus,
        submitCandidateCommentApi,
        deleteCommentApi,
        isLogin,
        setisLogin,
        AddCandidateApi,
        getallstatusApi,
        getCandidateDetailsApi,
        candidateView,
        setCandidateView,
        AddClientApi,
        getClientApi,
        updateClientApi,
        deleteClientApi,
        searchClientApi,
        clientPaginationApi,
        ClientEnableApi,
        client,
        setClient,
        allClientApi,
        allVendorApi,
        loader,
        appliedJobsPaginationApi,
        appliedJobs,
        recruiterPaginationApi,
        recruiter,
        setRecruiter,
        searchRecruiterApi,
        setAppliedJobs,
        viewCandidatePaginationApi,
        viewcandidate,
        setViewCandidate,
        getAllStatusApi,
        getAllTechnologyApi,
        getAllVendorsApi,
        deleteCandidateApi,
        openCandidate,
        setOpenCandidate,
        getAllOpenCandidateApi,
        allTechnology,
        setAllTechnology,
        getAllTechnologyWithoutTokenApi,
        subAdmin,
        setSubAdmin,
        subAdminPaginationApi,
        AllSubadminApi,
        AddSubAdminApi,
        updateSubAdminApi,
        deleteSubAdminApi,
        inHouseJob,
        setInHouseJob,
        inHouseJobPaginationApi,
        searchInHouseApi,
        adminApplyJobs,
        sendEmailtoAll,
        sendAllJobToVendor,
        sendAllCandidateToVendor
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
export default DataProvider;
