import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown, FaUsers } from "react-icons/fa";
import { FiTrash, FiEdit2, FiMail, FiEye } from "react-icons/fi";
import { DataContext } from "../../context/Datastate";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import DeleteModal from "../DeleteModal/DeleteModal";
import Pagination from "../Pagination/Pagination";
import VendorModal from "./VendorModal";
import EnableModal from "../EnableDisableModal/EnableModal";
import nodata from "../Assets/nodata.png";
import Loader from "../Loader/Loader";
import NoData from "../NoData/NoData";
import { FiSun } from 'react-icons/fi';

const VendorList = () => {
  const {
    loader,
    setVendor,
    vendor,
    globalLoader,
    deleteBtn,
    setDeleteBtn,
    setDeleteBtnId,
    deleteBtnId,
    deleteVendorApi,
    searchVendorApi,
    vendorPaginationApi,
    totalCount,
    pageCount,
    vendoMailApi,
    updateVendoEnableApi,
    setCurrentPage,
    technology,
    getAllTechnologyApi,
    city,
    allCityApi,
    updateVendorApi,
    getAllVendorsApi
  } = useContext(DataContext);

  const limit = 100;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [vendorModal, setVendorModal] = useState(false);
  const [enableModal, setEnableModal] = useState(false);
  const [vendorItem, setVendorItem] = useState({});
  const [enableVendorId, setEnableVendorId] = useState("");
  const [filterOption, setFilterOption] = useState("true"); // Default: Show all vendors
  const [filterHotOptions, setfilterHotOptions] = useState("true"); // Default: Show all vendors

  const [filteredVendors, setFilteredVendors] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTechnology, setSelectedTechnology] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [hotVendor, setHotVendor] = useState("");
  const [statusToUpdate, setStatusToUpdate] = useState(null);
  
  useEffect(() => {
    setCurrentPage(1);
    vendorPaginationApi(1, limit);
    getAllTechnologyApi();
    allCityApi();
  }, []);

  useEffect(() => {
    filterVendors(filterOption);
  }, [filterOption, vendor]);

  useEffect(() => {
    filterHOtVendors(filterHotOptions);
  }, [filterHotOptions, vendor]);

  const onKeyUpHandler = (e) => {
    const inputValue = e.target.value;

    // Update search input state
    setSearchInput(inputValue);

    // Check if the length of the input is at least 4 characters or it's empty
    if (inputValue.length >= 4 || inputValue.length === 0) {
      searchVendorApi(inputValue);
    }
  };
  // const onKeyUpHandler = (e) => {
  //   searchVendorApi(e.target.value);
  // };

  const changeHotVendorStatus = async (id, currentStatus) => {
    console.log(id, currentStatus, "ddd", !currentStatus)

    const updatedStatus = { hotVendor: !currentStatus };
    // setHotVendor(!currentStatus);
    await updateVendorApi(updatedStatus, id)
    setTimeout(async (e) => {
      await getAllVendorsApi()
      setHotVendor(prevHotVendor => !prevHotVendor);
    }, 1000)


  }
  const handleVendorMail = (id) => {
    vendoMailApi(id);
  };
  const filterVendors = (option) => {
    switch (option) {
      case "enabled":
        setFilteredVendors(vendor.filter((item) => item.isActive));
        break;
      case "disabled":
        setFilteredVendors(vendor.filter((item) => !item.isActive));
        break;
      default:
        setFilteredVendors(vendor);
        break;
    }
  };
  const filterHOtVendors = (option) => {
    switch (option) {
      case "hot-vendors":
        setFilteredVendors(vendor.filter((item) => item.hotVendor));
        break;
      case "vendors":
        setFilteredVendors(vendor.filter((item) => !item.hotVendor));
        break;
      default:
        setFilteredVendors(vendor);
        break;
    }
  };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...vendor].sort((a, b) => {
      const valueA = String(a[property]); // Convert to string
      const valueB = String(b[property]); // Convert to string

      const comparison = valueA.localeCompare(valueB);
      return isAscending ? comparison : -comparison;
    });

    setVendor(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-theme-color" : "text-sort-light";
    }
    return direction === "desc" ? "text-theme-color" : "text-sort-light";
  };

  const filterData = () => {
    vendorPaginationApi(
      1,
      limit,
      selectedLocation,
      selectedTechnology,
      filterOption
    );
  };

  const handleClear = () => {
    setSelectedLocation("");
    setSelectedTechnology("");
    setFilterOption("");
    vendorPaginationApi(1, limit);
  };

  const handleVendorDialog = (item) => {
    setVendorModal(true);
    setVendorItem(item);
  };
  const handleVendorDisabled = (item) => {
    updateVendoEnableApi(item?._id);
    setVendorItem(item);
    setEnableModal(false);
  };

  // State to manage the icon's color


  // Function to handle the icon click
  const handleIconClick = () => {
    setIsSelected(!isSelected); // Toggle the state
  };


  function convertISODateToFormattedDate(isoDateString) {
    const date = new Date(isoDateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <>
      {deleteBtn ? (
        <DeleteModal deleteApi={deleteVendorApi} deleteId={deleteBtnId} />
      ) : (
        ""
      )}
      {vendorModal ? (
        <VendorModal item={vendorItem} setVendorModal={setVendorModal} />
      ) : null}
      {enableModal ? (
        <EnableModal
          item={vendorItem}
          setEnableModal={setEnableModal}
          apiFunction={handleVendorDisabled}
        />
      ) : null}
      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3">
            <div className="flex justify-between items-center">
              <h1 className="text-xl text-[#202223] font-semibold">
                Vendors List
              </h1>
              <div className="flex gap-1">
                <Link to="/vendors/add">
                  <button className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm">
                    Add New Vendor
                  </button>
                </Link>
              </div>
            </div>
            <div className=" flex justify-between space-x-2 pt-2 items-start">
              <div className="flex gap-1 flex-wrap gap-y-2">
                <select
                  className="p-2 text-sm bg-transparent border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded outline-none"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                >
                  <option value="">Select City</option>
                  {city.map((item) => (
                    <option value={item.cityname}>{item.cityname}</option>
                  ))}
                </select>
                <select
                  className="p-2 text-sm bg-transparent border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded outline-none"
                  value={selectedTechnology}
                  onChange={(e) => setSelectedTechnology(e.target.value)}
                >
                  <option value="">Select Technology</option>
                  {technology.map((item) => (
                    <option value={item?._id}>{item.technologyname}</option>
                  ))}
                </select>
                <select
                  className="p-2 text-sm mx-4 bg-transparent border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded outline-none"
                  value={filterOption}
                  onChange={(e) => setFilterOption(e.target.value)}
                >
                  <option selected value="true">
                    Enabled
                  </option>
                  <option value="">Show All</option>
                  <option value="false">Disabled </option>
                </select>
                


                <select
                  className="p-2 text-sm bg-transparent border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded outline-none"
                  value={filterHotOptions}
                  onChange={(e) => setfilterHotOptions(e.target.value)}
                >
                  <option value="all">Show All</option>
                  <option value="hot-vendors">Hot Vendor </option>
                </select>


                <button
                  className="bg-theme-color mx-2 border text-white p-2 text-sm"
                  onClick={filterData}
                >
                  Submit
                </button>
                <button
                  className="p-2 bg-theme-color border text-white text-sm"
                  onClick={handleClear}
                >
                  Reset
                </button>
                {/* <button className="border border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                  Export XLS
                </button>
                <Link to="/import_speak">
                  <button className="border border-[#BABFC3]   px-4 py-2 text-[#202223] font-medium bg-white  rounded  text-sm">
                    Import XLS
                  </button>
                </Link> */}
              </div>
              <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem] left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
          </div>
          {/*--------- table list--------- */}

          {loader ? (
            <Loader />
          ) : vendor.length > 0 ? (
            <>
              <div className="h-[75vh] relative overflow-y-auto slide-container">
                <table className="w-full table-auto border-collapse bg-white shadow-md rounded-lg  ">
                  <thead className="cursor-pointer sticky top-0 z-[4]">
                    <tr className="text-left bg-theme-light text-theme-color text-xs font-normal   ">
                      <th className=" px-4 py-3 flex items-center ">
                        <div className="flex items-center gap-4">
                          <p>Vendor Name</p>
                          <div className="px-2 ">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "vendorname",
                                  "asc"
                                )}`}
                                onClick={() => sortData("vendorname")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "vendorname",
                                  "desc"
                                )}`}
                                onClick={() => sortData("vendorname")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Address</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "address",
                                  "asc"
                                )}`}
                                onClick={() => sortData("address")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "address",
                                  "desc"
                                )}`}
                                onClick={() => sortData("address")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Contact Name</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "contactpersonname",
                                  "asc"
                                )}`}
                                onClick={() => sortData("contactpersonname")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "contactpersonname",
                                  "desc"
                                )}`}
                                onClick={() => sortData("contactpersonname")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      {/* <th className=" px-4 ">
                  Alternate Name
                    <div className="px-[92px] ">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] absolute ${getSortArrowClassName(
                            "alternatepersonname",
                            "asc"
                          )}`}
                          onClick={() => sortData("alternatepersonname")}
                        />
                        <FaSortDown
                          className={`top-[40%] absolute ${getSortArrowClassName(
                            "alternatepersonname",
                            "desc"
                          )}`}
                          onClick={() => sortData("alternatepersonname")}
                        />
                      </span>
                    </div>
                  </th> */}
                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Contact Phone</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "contactpersonphone",
                                  "asc"
                                )}`}
                                onClick={() => sortData("contactpersonname")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "contactpersonphone",
                                  "desc"
                                )}`}
                                onClick={() => sortData("contactpersonphone")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th className=" px-4 ">
                        <div className="flex items-center gap-4">
                          <p>Last Login</p>
                          <div className="">
                            <span className="">
                              <FaSortUp
                                className={`top-[30%]  ${getSortArrowClassName(
                                  "lastLogIn",
                                  "asc"
                                )}`}
                                onClick={() => sortData("lastLogIn")}
                              />
                              <FaSortDown
                                className={`top-[40%] mt-[-8px]  ${getSortArrowClassName(
                                  "lastLogIn",
                                  "desc"
                                )}`}
                                onClick={() => sortData("lastLogIn")}
                              />
                            </span>
                          </div>
                        </div>
                      </th>
                      {/* <th className=" px-4 ">
                  Alternate Phone
                    <div className="px-[94px] ">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] absolute ${getSortArrowClassName(
                            "alternatepersonphone",
                            "asc"
                          )}`}
                          onClick={() => sortData("alternatepersonphone")}
                        />
                        <FaSortDown
                          className={`top-[40%] absolute ${getSortArrowClassName(
                            "alternatepersonphone",
                            "desc"
                          )}`}
                          onClick={() => sortData("alternatepersonphone")}
                        />
                      </span>
                    </div>
                  </th> */}
                      {/* <th><p>View Candidates</p></th> */}
                      <th className=" px-4 py-2 text-center rounded-tr-lg">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                    {filteredVendors?.map((item) => (
                      <tr
                        key={item?._id}
                        className="border-t text-left text-[14px] text-[#202223] relative hover:z-[3] font-normal hover:shadow-tableRowsShadow  cursor-pointer"
                      >
                        <td className="px-4 py-2">
                          {item?.vendorname}{" "}
                          <span>({item?.candidatecount})</span>
                        </td>
                        <td className="px-4 py-2">{item?.address}</td>
                        <td className="px-4 py-2">{item?.contactpersonname}</td>
                        {/* <td className="px-4 py-2">{item?.alternatepersonname}</td> */}
                        <td className="px-3 py-2">
                          {item?.contactpersonphone}
                        </td>
                        <td className="px-4 py-2">
                          {item.lastLogIn
                            ? convertISODateToFormattedDate(item.lastLogIn)
                            : "not available"}
                        </td>

                        <td className="px-4 py-2">
                          <div className="flex items-center  justify-center text-black ">

                            <FiSun
                              title="Hot Vendor"
                              className={`hover:rounded-full px-[6px] py-0 text-[32px] ${item.hotVendor ? 'text-yellow-700' : 'text-gray-700'}`} // Apply color based on item.hotVendor
                              onClick={() => {
                                console.log("cccc", item.hotVendor);
                                changeHotVendorStatus(item._id, item.hotVendor); // Pass current hotVendor status
                              }}
                            />

                            <Link
                              className="flex justify-center"
                              to={`/cadidatelist-vendor/${item?._id}`}
                              title="view candidates"
                            >
                              <FaUsers className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]" />
                            </Link>

                            <FiEye
                              title="view vendor details"
                              className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"
                              onClick={() => handleVendorDialog(item)}
                            />
                            <FiMail
                              title="send credentials"
                              className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"
                              onClick={() => handleVendorMail(item?._id)}
                            />
                            <Link
                              to={`/vendors/update/${item?._id}`}
                              title="edit vendor"
                            >
                              <FiEdit2 className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] " />
                            </Link>
                            <FiTrash
                              className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px]"
                              title="delete vendor"
                              onClick={() => {
                                setDeleteBtn(true);
                                setDeleteBtnId(item?._id);
                              }}
                            />
                            <span>
                              <label
                                title={item.isActive ? "disable" : "enable"}
                                class="flex items-center cursor-pointer"
                              >
                                <div class="relative">
                                  <input
                                    type="checkbox"
                                    title={item.isActive ? "disable" : "enable"}
                                    className="sr-only dot"
                                    checked={item?.isActive}
                                    onClick={() => {
                                      setVendorItem(item);
                                      setEnableModal(true);
                                    }}
                                  />
                                  <div class="w-[24px] h-[11px] bg-gray-400 rounded-full shadow-inner"></div>
                                  <div class="dot vend_act absolute w-[18px] h-[18px] bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                                </div>
                              </label>
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {/*<tr className=" border-t text-left text-sm text-[#202223] font-normal group hover:shadow-tableRowsShadow cursor-pointer">
                  <td className=" px-4 py-2 rounded-bl-lg"><img src={profileImg} className="w-16 h-16 rounded-[2px]"/></td>
                  <td className=" px-4 py-2">Mr.</td>
                  <td   className="px-4 py-2">James Devine</td>
                  <td   className="px-4 py-2 ">Principal Solutions Architect</td>
                  <td   className="px-4 py-2 ">Amazon Web Services</td>
                  <td className='rounded-br-lg'> <div className="flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity text-[18px] gap-4 text-theme-color"> <FiTrash/> <FiEdit2/> </div></td>
                 
                    </tr>*/}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between items-center ">
                <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${pageCount === 1 ? totalCount : secondResultCount
                  } of ${totalCount} results`}</span>
                <Pagination
                  firstResultCount={firstResultCount}
                  setFirstResultCount={setFirstResultCount}
                  secondResultCount={secondResultCount}
                  setSecondResultCount={setSecondResultCount}
                  limit={limit}
                  api={vendorPaginationApi}
                />
              </div>
            </>
          ) : null}

          {((!loader && !vendor) || vendor.length == 0) && <NoData />}

          {/* )} */}

          <div>
            <span className="font-[150px"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorList;
